import { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { message } from 'antd'

import PhysicianAPI from "../../../../../../../axios/physician/PhysicianApi"
import { useDispenseUnits } from './useDispenseUnits'
import { useCaseViewContext } from '../../../../../context/CaseViewContext'
import prescribeMedicine from '../../../../../../../redux/prescribeMedicine'

export function usePrescriptionTemplates() {
    const [templates, setTemplates] = useState([])
    const [selectedTemplate, selectTemplate] = useState(null)
    const { getDispenseUnitById } = useDispenseUnits()
    const dispatch = useDispatch()
    const { caseDetails } = useCaseViewContext()

    const categoryId = caseDetails?.category?._id
    const casePharmacyId = caseDetails?.pharmacyId

    const searchTemplates = useCallback(async ({ drugName, strength, DAW }) => {
        const hasCategoryId = !!categoryId;
        const hasBothDrugNameAndStrength = !!drugName && !!strength;

        if (!hasCategoryId && !hasBothDrugNameAndStrength) {
            return
        }

        try {
            const { data: { templates } } = await PhysicianAPI.searchPrescriptionTemplates({
                categoryId,
                drugName,
                strength,
                DAW
            })
            setTemplates(templates)
            return templates
        } catch (e) {
            message.error("Error fetching prescription templates")
            console.log(e)
        }
    }, [categoryId])

    const _selectTemplate = useCallback(({
        template,
        templateAlternatives,
        requestedMedication
    }) => {

        const {
            medication,
            pharmacyNotes,
            pharmacyId,
            DAW
        } = template

        const {
            refills,
            daysSupply,
            pillCount,
            dispenseUnitId,
            drugName
        } = medication

        const dispenseUnit = getDispenseUnitById(dispenseUnitId)
        const refillsToPrescribe = Number(refills?.toString() || requestedMedication?.refills?.toString() || 0)
        const daysSupplyToPrescribe = Number(daysSupply?.toString() || requestedMedication?.daysSupply?.toString() || 30)
        const quantityToPrescribe = pillCount?.toString() || requestedMedication?.pillCount?.toString() || 0

        const prescriptionToQueue = {
            ...template,
            ingredients: null,
            ncd: null,
            ...medication,
            name: drugName,
            quantityUnits: dispenseUnit?.Name,
            providePharmacyNotes: !!pharmacyNotes,
            refills: refillsToPrescribe,
            daysSupply: daysSupplyToPrescribe,
            quantity: quantityToPrescribe,
            dispense: requestedMedication.dispense,
            startDate: new Date(),
            valid: false,
            noSubstitutions: !!DAW,
            _id: requestedMedication._id,
            requestedMedication,
            templateAlternatives
        }

        dispatch(prescribeMedicine.actions.setNewPharmacyForRx(pharmacyId || casePharmacyId))
        return prescriptionToQueue
    }, [dispatch, getDispenseUnitById, casePharmacyId])

    const templatizePrescription = useCallback(async ({ requestedMedication }) => {
        const templates = await searchTemplates({
            drugName: requestedMedication.drugName,
            strength: requestedMedication.dosage
        })

        if (!templates?.[0]) {
            return null
        }

        return _selectTemplate({
            template: templates[0],
            templateAlternatives: templates,
            requestedMedication
        })
    }, [searchTemplates, _selectTemplate])

    const resetTemplateSearch = () => {
        setTemplates([])
        selectTemplate(null)
    }

    useEffect(() => {
        searchTemplates({ categoryId })
    }, [categoryId, searchTemplates])

    return {
        templates,
        selectedTemplate,
        _selectTemplate,
        searchTemplates,
        selectTemplate,
        resetTemplateSearch,
        templatizePrescription,
    };
}

import types from "./types";
import API from "../../axios/physician/PhysicianApi";
import handleAPIErrorResponse from "../../utils/handleAPIResponseError";

import { message } from "antd";

const setCaseTicketListLoading = (status) => ({
  type: types.SET_CASE_TICKETS_LIST_LOADING,
  data: status,
});

const setCaseTicketList = (payload, count) => ({
  type: types.SET_CASE_TICKETS_LIST,
  payload,
  count,
});

const setCaseTicketNotificationCount = (count) => ({
  type: types.SET_CASE_TICKET_NOTIFICATION_COUNT,
  count,
});

const setNotificationsCountByTicketIds = (payload) => ({
  type: types.SET_NOTIFICATIONS_COUNT_BY_TICKET_IDS,
  payload,
});

function getCaseTicketList(status) {
  return (dispatch) => {
    dispatch(setCaseTicketListLoading(true));
    API.getCaseTicketList(status)
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          const { payload } = data;
          if (payload.data) {
            const mappedData = payload.data.map((item) => {
              return {
                ...item,
                ticketno: item._id,
                lastResponseTime: item.lastResponseTime,
                status: item.status,
                caseno: item.caseId,
                unreadCount: item.unreadCount,
              };
            });
            const mappedCount = payload.count;
            dispatch(setCaseTicketList(mappedData, mappedCount));
          }
          message.success("Case Tickets Listed");
        } else {
          message.warning("Unable to list Case Tickets");
        }
        dispatch(setCaseTicketListLoading(false));
      })
      .catch((error) => {
        handleAPIErrorResponse(
          error,
          "Error occured while listing Case Tickets"
        );
        dispatch(setCaseTicketListLoading(false));
      });
  };
}

function getCaseTicketNotificationCount() {
  return (dispatch) => {
    API.getCaseTicketNotifications()
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          const { payload } = data;
          if (payload) {
            const mappedCount = payload.totalCount;
            dispatch(setCaseTicketNotificationCount(mappedCount));
          }
        } else {
          console.log("Unable to get Case Tickets Notification Count");
        }
      })
      .catch((error) => {
        handleAPIErrorResponse(
          error,
          "Error occured while getting Case Ticket Notification Count"
        );
      });
  };
}

function getNotificationsCountByTicketIds(ticketIds) {
  return (dispatch) => {
    API.getNotificationsCountByTicketIds(ticketIds)
      .then((res) => {
        if (!res.data.success) {
          throw Error();
        }
        console.log("Case Ticket Notification Count by ticketId", res.data.payload);
        dispatch(setNotificationsCountByTicketIds(res.data.payload));
      })
      .catch((error) => {
        handleAPIErrorResponse(
          error,
          "Error occured while getting Case Ticket Notification Count by ticketId"
        );
      });
  };
}

function markTicketAsRead(ticketId, successCallback) {
  return (dispatch) => {
    API.markTicketAsRead({ ticketId })
      .then(res => {
        if (res.data?.success) {
          successCallback && successCallback();
          API.getCaseTicketNotifications()
            .then((res) => {
              const { data } = res;
              if (data && data.success) {
                const { payload } = data;
                if (payload) {
                  const mappedCount = payload.totalCount;
                  dispatch(setCaseTicketNotificationCount(mappedCount));
                }
              } else {
                console.log("Unable to get Case Tickets Notification Count");
              }
            })
            .catch((error) => {
              handleAPIErrorResponse(
                error,
                "Error occured while getting Case Ticket Notification Count"
              );
          });
        } else {
          console.log("Unable to mark ticket as read");
        }
      })
  }
}

const actions = {
  getCaseTicketList,
  getCaseTicketNotificationCount,
  getNotificationsCountByTicketIds,
  markTicketAsRead,
};

export default actions;

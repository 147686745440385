import { useState, useEffect, useCallback } from 'react';
import { message } from 'antd'
import ChatRoomAPI from '../../../../../axios/ChatRoomAPI'

export const useMessaging = ({
    caseDetails,
    setMessages
}) => {
    const [patientMessages, setPatientMessages] = useState({
        list: [],
        count: 0,
    });

    const fetchPatientMessageList = useCallback(async () => {
        if (!caseDetails?.caseId) return
        try {
            const { data } = await ChatRoomAPI.getChatRoomMessages(caseDetails.caseId)

            if (!data?.success) {
                throw new Error("Error fetching patient messages")
            }

            const list = data.payload.messages

            setPatientMessages({
                list,
                count: data.payload.notificationCount,
            })

            return list
        } catch (e) {
            message.error(e)
        }
    }, [caseDetails?.caseId]);

    const sendPatientMessage = async message => {

        const payload = {
            chatRoomId: caseDetails?.caseId,
            isPatient: false,
            message,
            type: 'text',
            key: null
        }

        try {
            const { data } = await ChatRoomAPI.addMessagesToChatRoom(payload)
            if (!data.success) {
                throw new Error('Error sending message')
            }
            fetchPatientMessageList().then((list) => {
                if (!list) return
                setMessages({ list, loading: false })
            })
        } catch (e) {
            message.error(e.response?.data?.message || e.message)
        }
      };

    const clearPatientChatNotfications = useCallback(async () => {
        if (!caseDetails?.caseId) return
        try {
            const { data } = await ChatRoomAPI.clearChatRoomNotifications(caseDetails.caseId)
            if (!data.success) {
                throw new Error('Error clearinging patient chat notifications')
            }
        } catch (e) {
            message.error(e.response?.data?.message || e.message)
        }
    }, [caseDetails?.caseId]);

    useEffect(() => {
        fetchPatientMessageList()
        clearPatientChatNotfications()
    }, [caseDetails?.status, fetchPatientMessageList, clearPatientChatNotfications])

    return {
        fetchPatientMessageList,
        sendPatientMessage,
        patientMessages
    }
}

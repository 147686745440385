import { useState, useEffect, useCallback } from 'react'

import { message } from 'antd'

import PrescriptionsApi from '../../../../../../../axios/prescription/PrescriptionsApi'

export const useDispenseUnits = () => {
    const [dispenseUnitState, setDispenseUnitState] = useState({
        dispenseUnits: [],
        dispenseUnitsLoading: false
    })

    const fetchDispensUnits = useCallback(() => {
        setDispenseUnitState(prev => ({
            ...prev,
            dispenseUnitsLoading: true
        }))

        PrescriptionsApi.getAllAvailableDispenseUnits()
            .then(({ data }) => {
                setDispenseUnitState(prev => ({
                    ...prev,
                    dispenseUnits: data?.units || []
                }))
            })
            .catch(e => message.error(e.message))
            .finally(() => {
                    setDispenseUnitState(prev => ({
                        ...prev,
                        dispenseUnitsLoading: false
                    }))
            })
    }, [])

    const getDispenseUnitById = useCallback((unitId) => { 
        return dispenseUnitState.dispenseUnits.find(unit => unit.StandardDispenseUnitTypeID?.toString() === unitId?.toString())
    }, [dispenseUnitState.dispenseUnits])

    useEffect(() => {
        fetchDispensUnits()
    }, [fetchDispensUnits])

    return {
        ...dispenseUnitState,
        getDispenseUnitById,
    }
}

import { message } from "antd";
import API from "../../axios/physician/PhysicianApi";
import handleAPIErrorResponse from "../../utils/handleAPIResponseError";
import { consultedCasesConstants } from "../../constants";
import types from "./types";

const setCompletedCaseListLoading = (status) => ({
  type: types.SET_COMPLETED_CASE_LIST_LOADING,
  data: status,
});

const setCompletedNoRxCaseListLoading = (status) => ({
  type: types.SET_COMPLETED_NO_RX_CASE_LIST_LOADING,
  data: status,
});

const setCompletedCaseCaseList = (payload, count) => ({
  type: types.SET_COMPLETED_CASE_LIST,
  payload,
  count,
});

const setCompletedNoRxCaseList = (payload, count) => ({
  type: types.SET_COMPLETED_NO_RX_CASE_LIST,
  payload,
  count,
});

function getConsultedCaseList(type, params) {
  return (dispatch) => {
    if (type === consultedCasesConstants["consultComplete"]) {
      dispatch(setCompletedCaseListLoading(true));
    }
    if (type === consultedCasesConstants["consultCompleteNoRx"]) {
      dispatch(setCompletedNoRxCaseListLoading(true));
    }
    API.getCaseList(params)
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          const { payload } = data;
          if (payload.data) {
            if (type === consultedCasesConstants["consultComplete"]) {
              dispatch(setCompletedCaseCaseList(payload.data, payload.count));
            }
            if (type === consultedCasesConstants["consultCompleteNoRx"]) {
              dispatch(setCompletedNoRxCaseList(payload.data, payload.count));
            }
          }
        } else {
          message.warning("Unable to update Consulted Cases");
        }
        if (type === consultedCasesConstants["consultComplete"]) {
          dispatch(setCompletedCaseListLoading(false));
        }
        if (type === consultedCasesConstants["consultCompleteNoRx"]) {
          dispatch(setCompletedNoRxCaseListLoading(false));
        }
      })
      .catch((error) => {
        if (type === consultedCasesConstants["consultComplete"]) {
          handleAPIErrorResponse(error, "Error occured while fetching Consult Completed Cases")
          dispatch(setCompletedCaseListLoading(false));
        }
        if (type === consultedCasesConstants["consultCompleteNoRx"]) {
          handleAPIErrorResponse(error, "Error occured while fetching Consult Complete No Rx Cases")
          dispatch(setCompletedNoRxCaseListLoading(false));
        }
        console.log(
          "=========================== consult complete cases API error =============================",
          error
        );
      });
  };
}


const actions = {
  setCompletedCaseListLoading,
  setCompletedNoRxCaseListLoading,
  setCompletedCaseCaseList,
  setCompletedNoRxCaseList,
  getConsultedCaseList,
};

export default actions;
import types from "./types";

const initState = {
  caseTicketListLoading: false,
  caseTicketList: [],
  caseTicketCount: 0,
  caseTicketNotificationCount: 0,
};

export default function caseTickets(state = initState, action) {
  switch (action.type) {
    case types.SET_CASE_TICKETS_LIST_LOADING:
      return {
        ...state,
        caseTicketListLoading: action.data,
      };
    case types.SET_CASE_TICKETS_LIST:
      return {
        ...state,
        caseTicketCount: action.count,
        caseTicketList: action.payload,
      };
    case types.SET_CASE_TICKET_NOTIFICATION_COUNT:
      return {
        ...state,
        caseTicketNotificationCount: action.count,
      };
    case types.SET_NOTIFICATIONS_COUNT_BY_TICKET_IDS:
      const updatedTicketIds = Object.keys(action.payload);
      const ticketMap = new Map(state.caseTicketList.map(ticket => [ticket._id, ticket]));
      updatedTicketIds.forEach(ticketId => {
        if (ticketMap.has(ticketId)) {
          ticketMap.set(ticketId, { ...ticketMap.get(ticketId), ...action.payload[ticketId] });
        } else {
          ticketMap.set(ticketId, action.payload[ticketId]);
        }
      });
      const updatedCaseTicketList = Array.from(ticketMap.values());
      return {
        ...state,
        caseTicketList: updatedCaseTicketList,
      };

    default:
      return state;
  }
}

import { useState, useCallback } from 'react';
import TicketAPI from '../axios/TicketApi';
import { useEffect } from 'react';

export const useTicketByCaseId = (caseId) => {
    const [ticket, setTicket] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchTicket = useCallback(async () => {
        setLoading(true);
        try {
            const { data } = await TicketAPI.getTicketByCaseId(caseId);
            if (!data.success) {
                throw new Error('Error fetching ticket');
            }
            data.payload && setTicket(data.payload);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }, [caseId]);

    useEffect(() => {
        fetchTicket();
    }, [fetchTicket]);

    return {
        ticket,
        loading,
        fetchTicket
    }
}

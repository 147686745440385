import React, { useState } from 'react'
import { Tooltip } from 'antd'
import { SendOutlined, PaperClipOutlined } from '@ant-design/icons'
import { AntTinyWrapper } from '../../../../../components/AntTinyWrapper'
import { ButtonGroup } from './Controls.styles'
import { StyledButton } from '../../Button'
import MessageTemplateButton from './MessageTemplateButton'
import { ChatType } from '../types'

import { useCaseViewContext } from '../../../context/CaseViewContext'

export const Controls = ({
    chatContext,
    showModal,
    handleSendMessage
}) => {
    const { caseDetails, provider } = useCaseViewContext()
    const [messageEntered, setMessageEntered] = useState('');


    const handleEditorChange = val => {
        setMessageEntered(val);
    }

    const onSubmit = async () => {
        if (!messageEntered?.length) {
            return
        }

        await handleSendMessage(messageEntered)
        setMessageEntered('')
    }

    const ButtonGroupByChatType = {
        [ChatType.PATIENT]: (
            <div style={{ display: 'flex', gap: '3px' }}>
                <MessageTemplateButton
                    caseDetails={caseDetails}
                    physicianDetails={provider}
                    setText={setMessageEntered}
                />
                <Tooltip
                    title="Attach File"
                >
                    <StyledButton
                        size="medium"
                        Icon={PaperClipOutlined}
                        onClick={showModal}
                    />
                </Tooltip>
            </div>
        ),
        [ChatType.ADMIN]: <div />,
    }

    return (
        <div style={{ height: '240px' }}>
            <AntTinyWrapper
                value={messageEntered}
                onChange={handleEditorChange}
                onKeyDown={event => {
                    if (!event.shiftKey && event.key === 'Enter') {
                        onSubmit()
                    }
                }}
            />
            <ButtonGroup>
                {ButtonGroupByChatType[chatContext] || ButtonGroupByChatType[ChatType.PATIENT]}
                <StyledButton
                    size="medium"
                    Icon={SendOutlined}
                    tooltip="Send"
                    onClick={onSubmit}
                />
            </ButtonGroup>
        </div>
    )
}

import moment from 'moment-timezone'
import { CaseFilterMap, CaseFilterToQueryParamMap } from "../constants/CaseFilter";
import { PaginateParamMap } from '../constants/PaginateParams';

const { INDEX, LIMIT, SORT, SORT_BY } = PaginateParamMap;

export const createCaseFilterQueryParamMap = (caseListFilterMap, hideFields = []) => {
    if (!caseListFilterMap) return {};

    const filterMapWithAvailableFields = hideFields
      .reduce((filter, field) => {
        delete filter[field];
        return filter;
      }, { ...caseListFilterMap });

    const filterMapWithDefinedFields = {};
    for (let key in filterMapWithAvailableFields) {
      if (filterMapWithAvailableFields[key] !== undefined) {
        filterMapWithDefinedFields[key] = filterMapWithAvailableFields[key];
      }
    };

    const params = {};
    Object.entries(filterMapWithDefinedFields).forEach(([key, value]) => {
      const paramsKey = CaseFilterToQueryParamMap[key];
      if (!paramsKey) {
        return;
      }

      if ([
        CaseFilterMap.CREATED_DATE_RANGE,
        CaseFilterMap.UPDATED_DATE_RANGE,
        CaseFilterMap.COMPLETED_DATE_RANGE,
      ].includes(key)) {
        params[paramsKey] = value.map(date => moment(date).format("YYYY-MM-DD"));
      }
      else if ([CaseFilterMap.CREATED_DATE, CaseFilterMap.UPDATED_DATE].includes(key)) {
        params[paramsKey] = moment(value).format("YYYY-MM-DD");
      }
      else if (key === CaseFilterMap.BOOKMARKED && value === false) {
        delete params[paramsKey];
      }
      else {
        params[paramsKey] = value;
      }
    });

    return params;
}

export const createTableQueryParamMap = (tablePreferences) => {
  if (!tablePreferences) return {};
  const { currentPage, tablePageSize, sortOrder, sortBy } = tablePreferences;
  const tableFilterMap = {
    [INDEX]: currentPage,
    [LIMIT]: tablePageSize,
    [SORT]: sortOrder,
  };
  if (sortBy) {
    tableFilterMap[SORT_BY] = sortBy;
  }
  return tableFilterMap;
}

export const createTableSortByMap = (table) => {
  const SortByMap = {
    [table.PATIENT_NAME]: 'patientData.firstName',
    [table.TELEMEDICINE_CATEGORY_ID]: 'categoryData.name',
    [table.PHYSICIAN_ID]: 'physicianData.name',
    [table.STATE_ID]: 'state',
    [table.UNREAD_CHAT_NOTIFICATION]: 'readNotifications',
    [table.SUBMITTED_AT]: 'latestRxRenewalRequest.createdAt',
    [table.LAST_MESSAGE_TO_PATIENT_AT]: 'chatData.lastMessageToPatientAt',
  }
  return SortByMap;
}

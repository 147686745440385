export const ChatType = {
  PATIENT: 'patient',
  ADMIN: 'admin',
}

export const TicketStatus = {
  OPEN: 'open',
  CLOSED: 'closed',
  SOLVED: 'solved'
}
